import qs from "qs";
import * as axiosHelper from "./api_helper";

export const getAddresses = async ({ payload }) => {
  const data = await axiosHelper.get(`/address-management?${qs.stringify(payload)}`);
  return data;
};

export const addNewAddress = async (data = {}) => {
  const result = await axiosHelper.post("/address-management/create", data);
  return result;
};

export const fetchMasterWallet = async () => {
  const data = await axiosHelper.get("/address-management/master-wallet");
  return data;
};

export const fetchAddressBalance = async (address) => {
  const data = await axiosHelper.get(`/address-management/balance/${address}`);
  return data;
};

export const withdrawFromAddress = async (data) => {
  const result = await axiosHelper.post("/address-management/withdraw", data);
  return result;
};